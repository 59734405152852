import ProductPluginInterface from "@scripts/components/product/plugins/ProductPluginInterface.js";

export default class Omnibus extends ProductPluginInterface {
  #compatibleTypes = [];

  constructor(compatibleTypes) {
    super(compatibleTypes);
  }

  init() {
    this.addChangeVariationListener();
  }

  addChangeVariationListener() {
    jQuery(".variations_form").on("found_variation", (event, variation) => {
      let omnibusText = variation.omnibus;
      this.updateOmnibus(omnibusText ?? "");
    });
  }

  updateOmnibus(omnibusText) {
    let wrapper = document.querySelector("[omnibus-variation-info-wrapper]");
    if (wrapper) {
      wrapper.innerHTML = omnibusText;
    }
  }
}
