import ProductPluginInterface from "@scripts/components/product/plugins/ProductPluginInterface.js";
import Mediator from "@scripts/core/Mediator.js";

export default class Multipack extends ProductPluginInterface {
  #compatibleTypes = [];
  #buttons = [];
  #mainRulesDomWrapper = null;
  #currentProductID = null;
  #rules = {};
  #productType = null;

  constructor(compatibleTypes, productType) {
    super(compatibleTypes);
    this.#compatibleTypes = compatibleTypes;
    this.#productType = productType;
  }

  init(productType) {
    if (!this.isCompatible(productType)) return;

    this.setWrapper();
    this.setRules();
    this.setHTMLDomButtons();
    this.handleShowVariation();
    this.handleButtonClick();
    this.initialSyncQuantityInputWithButton();

    Mediator.subscribe("quantityInputChanged", (input) => {
      this.syncQuantityInputWithButton(input);
    });
  }

  setWrapper() {
    this.#mainRulesDomWrapper = document.querySelector(
      "[data-multipack-rules]",
    );
  }

  setHTMLDomButtons() {
    this.#buttons = document.querySelectorAll("[data-select-multipack]");
  }

  handleButtonClick() {
    this.#buttons.forEach((button) => {
      button.addEventListener("click", (event) => {
        event.preventDefault();
        let value = button.dataset.minQuantity;
        // find quantity input and change value

        let quantityInput = this.getQuantityInput();

        if (quantityInput) {
          quantityInput.value = parseInt(value);
          quantityInput.dispatchEvent(new Event("change"));
        }
      });
    });
  }

  handleShowVariation() {
    jQuery(document).on("show_variation", (event, variation, purchasable) => {
      this.handleMultipackButtons(variation, purchasable);
      this.syncQuantityInputWithButton();
    });
  }

  handleMultipackButtons(variation, purchasable) {
    console.log("handleMultipackButtons", variation);
    let variation_id = variation.variation_id;
    if (!variation_id) {
      return;
    }

    this.#currentProductID = variation_id;

    let activeButtonsCount = 0;

    this.#buttons.forEach((button) => {
      let buttonVariationId = button.dataset.variationId;
      if (parseInt(buttonVariationId) === variation_id && purchasable) {
        button.style.display = "flex";
        activeButtonsCount++;
      } else {
        button.style.display = "none";
      }
    });

    this.toggleModule(activeButtonsCount);
  }

  toggleModule(activeButtonsCount) {
    if (!this.#mainRulesDomWrapper) {
      return;
    }
    
    if (activeButtonsCount > 0) {
      this.#mainRulesDomWrapper.style.display = "block";
    } else {
      this.#mainRulesDomWrapper.style.display = "none";
    }
  }

  setRules() {
    if (!this.#mainRulesDomWrapper) {
      return;
    }

    this.#rules = this.#mainRulesDomWrapper.dataset.multipackRules;
  }

  syncQuantityInputWithButton(quantityInput = null) {
    if (!quantityInput) {
      quantityInput = this.getQuantityInput();
    }

    if (!quantityInput) {
      console.error("Quantity input not found");
      return;
    }

    console.info("syncQuantityInputWithButton", quantityInput);

    let value = quantityInput.value;

    this.removeActiveClassFromButtons();

    this.#buttons.forEach((button) => {
      console.info(this.#productType);
      if (this.#productType === "variable") {
        this.compareVariableProduct(button, value);
      } else {
        this.compareSimpleProduct(button, value);
      }
    });
  }

  compareSimpleProduct(button, value) {
    let buttonValue = button.dataset.minQuantity;

    if (parseInt(value) >= parseInt(buttonValue)) {
      this.removeActiveClassFromButtons();
      button.classList.add("border-gray-900");
    }
  }

  compareVariableProduct(button, value) {
    let buttonValue = button.dataset.minQuantity;
    let buttonParentProductID = button.dataset.productId;
    let buttonVariationID = button.dataset.variationId;

    let productID = buttonVariationID
      ? buttonVariationID
      : buttonParentProductID;

    if (
      parseInt(value) >= parseInt(buttonValue) &&
      parseInt(productID) === parseInt(this.#currentProductID)
    ) {
      this.removeActiveClassFromButtons();
      button.classList.add("border-gray-900");
    }
  }

  removeActiveClassFromButtons() {
    this.#buttons.forEach((button) => {
      button.classList.remove("border-gray-900");
    });
  }

  getQuantityInput() {
    let quantityInput = document.querySelector(
      'form.cart input[name="quantity"]',
    );

    return quantityInput;
  }

  initialSyncQuantityInputWithButton() {
    this.syncQuantityInputWithButton();
  }
}
