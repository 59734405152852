import Debugger from "@scripts/core/Debugger.js";

export default class SingleProduct {
  #productType = null;
  #pluginListArr = [];

  constructor(productType, pluginListArr) {
    this.#productType = productType;
    this.#pluginListArr = pluginListArr;
  }

  init() {
    Debugger.debug("SingleProduct init");
    this.initPlugins();
  }

  initPlugins() {
    if (!this.productType) return;

    this.#pluginListArr.forEach((plugin) => {
      if (!plugin) return;
      plugin.init(this.#productType);
    });
  }

  get productType() {
    return this.#productType;
  }
}
