export default class ProductPluginInterface {
  _compatibleTypes = [];

  constructor(compatibleTypes = []) {
    this._compatibleTypes = compatibleTypes;
  }

  setCompatibleTypes(compatibleTypes) {
    this._compatibleTypes = compatibleTypes;
  }

  isCompatible(productType) {
    // Override this method in subclasses
    return this._compatibleTypes.includes(productType);
  }

  init(productType) {
    console.error(
      "init() method is not implemented in Object: " + this.constructor.name,
    );
  }
}
