import Debugger from "@scripts/core/Debugger.js";

export default class CountDownTimer {
  #remainingSeconds = 0;
  #timer = null;
  #element = null;
  #elementId = null;

  constructor(remainingSeconds) {
    this.#remainingSeconds = remainingSeconds;
    this.#elementId = "time-counter-" + Math.random().toString(36).substr(2, 9);
    this.#element = document.createElement("span");
    this.#element.className = "time-counter";
    this.#element.id = this.#elementId;
    this.#element.textContent = this.getFormattedTime();
  }

  getElement() {
    return this.#element;
  }

  start() {
    this.stop();

    this.#timer = setInterval(() => {
      this.#remainingSeconds--;

      Debugger.debug("CountDownTimer.interval", this.#remainingSeconds);

      if (this.#remainingSeconds <= 0) {
        this.stop();
        this.#remainingSeconds = 0;
      }

      // Znajdź element na stronie i zaktualizuj go
      const elementOnPage = document.getElementById(this.#elementId);
      if (elementOnPage) {
        elementOnPage.textContent = this.getFormattedTime();
      }

      Debugger.debug("CountDownTimer.element", elementOnPage);
    }, 1000);
  }

  stop() {
    if (this.#timer) {
      clearInterval(this.#timer);
      this.#timer = null;
    }
  }

  getFormattedTime() {
    const hours = Math.floor(this.#remainingSeconds / 3600);
    const minutes = Math.floor((this.#remainingSeconds % 3600) / 60);
    const seconds = this.#remainingSeconds % 60;

    const pad = (num) => String(Math.floor(num)).padStart(2, "0");
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
}
