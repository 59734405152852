import ProductPluginInterface from "@scripts/components/product/plugins/ProductPluginInterface.js";
import Mediator from "@scripts/core/Mediator.js";

export default class VariationSwitcher extends ProductPluginInterface {
  #variationButtons = [];
  #state = {};

  constructor(compatibleTypes) {
    super(compatibleTypes);

    this.#state = {
      init: false,
      currentVariation: null,
    };
  }

  init() {
    this.getVariationButtons();
    this.addEventListeners();
  }

  updateState(newState) {
    this.#state = {
      ...this.#state,
      ...newState,
    };

    this.refreshView();
  }

  refreshView() {
    this.selectButtonBasedOnVariation(this.#state.currentVariation);
  }

  selectButtonBasedOnVariation(currentVariation) {
    if (!currentVariation) {
      return;
    }

    let attributes = currentVariation;

    // remove keywoard 'attribute_' from attributes object keys
    Object.keys(attributes).forEach((key) => {
      const newKey = key.replace("attribute_", "");
      attributes[newKey] = attributes[key];
    });

    // remove all keys with 'attribute_' keyword
    Object.keys(attributes).forEach((key) => {
      if (key.includes("attribute_")) {
        delete attributes[key];
      }
    });

    // find button in this.#variationButtons DOM collection
    let variationButton = document.querySelector(
      `[data-switch-variation='${JSON.stringify(attributes)}']`,
    );

    if (variationButton) {
      this.removeSelectedClassFromAllButtons();
      variationButton.classList.add("is-selected");
    }
  }

  addEventListeners() {
    this.#variationButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        // check this button is already selected
        if (button.classList.contains("is-selected")) {
          return;
        }

        let attributes = button.dataset.switchVariation;
        this.onVariationButtonClick(attributes);

        Mediator.publish("changeVariation", null);
      });
    });
  }

  getVariationButtons() {
    this.#variationButtons = document.querySelectorAll(
      "[data-switch-variation]",
    );
  }

  resetWooVariationForm() {
    jQuery(".variations_form select").val("").change();
    jQuery(".variations_form").trigger("woocommerce_update_variation_values");
  }

  onVariationButtonClick(attributes) {
    attributes = JSON.parse(attributes);
    this.resetWooVariationForm();
    this.updateWooFormAttributes(attributes);

    let newState = {
      currentVariation: attributes,
    };

    this.updateState(newState);
  }

  updateWooFormAttributes(attributes) {
    // Update the form attributes
    let form = document.querySelector("form.variations_form");
    let selectFields = form.querySelectorAll("select");

    // loop for atributtes object keys and values
    for (let key in attributes) {
      let value = attributes[key];
      const fieldName = `attribute_${key}`;

      const field = form.querySelector(`select[name="${fieldName}"]`);
      field.value = value;
    }

    // trigger change event
    this.triggerChange(selectFields);
  }

  triggerChange(selectFields) {
    selectFields.forEach((field) => {
      jQuery(field).trigger("change");
    });
  }

  removeSelectedClassFromAllButtons() {
    this.#variationButtons.forEach((button) => {
      button.classList.remove("is-selected");
    });
  }
}
