import ProductPluginInterface from "@scripts/components/product/plugins/ProductPluginInterface.js";
import SplideSlider from "@scripts/components/sliders/SplideSlider.js";
import Config from "@scripts/core/Config.js";
import Debugger from "@scripts/core/Debugger.js";

export default class Gallery extends ProductPluginInterface {
  #compatibleTypes = []; // ['variable', 'simple', 'grouped', 'external', 'variation'];
  #slideLibrary = null;
  #slideObject = null;
  #mainSlider = null;
  #thumbnailsSlider = null;
  #sliderExists = null;
  #params = {};

  constructor(compatibleTypes, slideLibrary) {
    super(compatibleTypes);
    this.#mainSlider = false;
    this.#thumbnailsSlider = false;
    this.#sliderExists = false;
    this.#slideLibrary = slideLibrary;
    this.#slideObject = this.setSlideLibraryObject(this.#slideLibrary);

    this.#params = {
      mainSlider: {
        type: "fade",
        rewind: true,
        pagination: false,
        arrows: false,
        mediaQuery: "min",
        autoplay: true,
        interval: 5000,
        speed: 1000,
        breakpoints: {
          1024: {
            arrows: false,
          },
        },
      },
      thumbnailsSlider: {
        gap: 16,
        pagination: false,
        perPage: "auto",
        wheel: true,
        releaseWheel: true,
        arrows: false,
        isNavigation: true,
        height: "100%",
        lazyLoad: "sequential",
      },
    };
  }

  setSlideLibraryObject(slideLibrary) {
    switch (slideLibrary) {
      case "splide":
        return new SplideSlider();
      default:
        return null;
    }
  }

  init(productType) {
    if (!this.isCompatible(productType)) return;
    if (this.sliderExists) return;

    if (document.getElementById("main-slider")) {
      this.#mainSlider = this.#slideObject.createSliderWithParams(
        "#main-slider",
        this.#params.mainSlider,
      );
      this.#slideObject.mountSlider(this.#mainSlider);
    }

    if (document.getElementById("thumbnail-slider")) {
      this.#thumbnailsSlider = this.#slideObject.createSliderWithParams(
        "#thumbnail-slider",
        this.#params.thumbnailsSlider,
      );
      this.#slideObject.mountSlider(this.#thumbnailsSlider);
    }

    if (this.#mainSlider && this.#thumbnailsSlider) {
      this.#slideObject.setSync(this.#mainSlider, this.#thumbnailsSlider);
    }

    if (this.#mainSlider && !this.#thumbnailsSlider) {
      this.#slideObject.mountSlider(this.#mainSlider);
      this.#slideObject.setParams(this.#mainSlider, this.#params.mainSlider);
    }

    this.sliderExists = true;

    this.handleHooks();
  }

  checkRequiredElements() {
    let mainSlider = document.getElementById("main-slider");
    let thumbnailSlider = document.getElementById("thumbnail-slider");
    return !!(mainSlider && thumbnailSlider);
  }

  handleHooks() {
    if (!this.checkRequiredElements()) {
      return;
    }

    jQuery(".variations_form").on("found_variation", (event, variation) => {
      const id = variation.variation_id;

      if (!id) {
        return;
      }

      this.moveToVariationImage(id);
    });
  }

  moveToVariationImage(id) {
    const slides = this.#mainSlider.Components.Elements.slides;
    if (slides) {
      const index = slides.findIndex(
        (slide) => parseInt(slide.getAttribute("data-variation-id")) === id,
      );
      if (index !== -1) {
        this.#mainSlider.go(index);
      }
    }
  }
}
