import Mediator from "@scripts/core/Mediator.js";
import Debugger from "@scripts/core/Debugger.js";

export default class QuantityInput {
  #wrapper;
  #input;
  #minusButton;
  #plusButton;
  #callbacks = [];
  #eventListeners = [];

  constructor(wrapper) {
    this.#wrapper = wrapper;
    this.#input = wrapper.querySelector('input[type="number"]');
    this.#minusButton = wrapper.querySelector(".quantity-button.minus");
    this.#plusButton = wrapper.querySelector(".quantity-button.plus");

    this.#addListeners();
  }

  init() {
    this.#initListeners();
    this.#updateButtonStates();
  }

  #addListeners() {
    this.#eventListeners.push({
      element: this.#minusButton,
      event: "click",
      listener: this.#decrease.bind(this),
    });

    this.#eventListeners.push({
      element: this.#plusButton,
      event: "click",
      listener: this.#increase.bind(this),
    });

    this.#eventListeners.push({
      element: this.#input,
      event: "change",
      listener: this.#onChange.bind(this),
    });
  }

  #initListeners() {
    Debugger.debug("QuantityInput: initListeners");

    this.#eventListeners.forEach(({ element, event, listener }) => {
      element.addEventListener(event, listener);
    });
  }

  #removeListeners() {
    this.#eventListeners.forEach(({ element, event, listener }) => {
      element.removeEventListener(event, listener);
    });
  }

  #decrease() {
    const currentValue = parseInt(this.#input.value);
    const minValue = parseInt(this.#input.min) || 1;
    if (currentValue > minValue) {
      this.setValue(currentValue - 1);
    }
  }

  #increase() {
    const currentValue = parseInt(this.#input.value);
    const maxValue = this.#input.max ? parseInt(this.#input.max) : Infinity;
    if (currentValue < maxValue) {
      this.setValue(currentValue + 1);
    }
  }

  #onChange() {
    this.#updateButtonStates();
    this.#notifyValueChange();
  }

  setValue(value, notify = true) {
    this.#input.value = value;

    Debugger.debug(
      "QuantityInput: setValue",
      [this.#input, value],
      "info",
      "color: green; background-color: red;",
    );

    this.#updateButtonStates();

    if (notify) {
      this.#notifyValueChange();
    }
  }

  #updateButtonStates() {
    const currentValue = parseInt(this.#input.value);

    if (isNaN(currentValue) || currentValue === 0) {
      this.setValue(1, false);
      return;
    }

    const minValue = parseInt(this.#input.min) || 1;
    const maxValue = this.#input.max ? parseInt(this.#input.max) : Infinity;

    this.#setButtonState(this.#minusButton, currentValue <= minValue);
    this.#setButtonState(this.#plusButton, currentValue >= maxValue);
  }

  #setButtonState(button, isDisabled) {
    if (isDisabled) {
      button.classList.add("disabled");
      button.setAttribute("disabled", "disabled");
    } else {
      button.classList.remove("disabled");
      button.removeAttribute("disabled");
    }
  }

  addCallback(callback) {
    this.#callbacks.push(callback);
  }

  runCallbacks() {
    this.#callbacks.forEach((callback) => {
      callback(this.#wrapper, this.#input);
    });
  }

  #notifyValueChange() {
    const value = this.#input.value;
    Mediator.publish("quantityInputChanged", this.#input);
    this.runCallbacks();
  }

  getValue() {
    return this.#input.value;
  }

  getWrapper() {
    return this.#wrapper;
  }

  destroy() {
    this.#removeListeners();
  }
}
