import Mediator from "@scripts/core/Mediator.js";
import Debugger from "@scripts/core/Debugger.js";
import CountDownTimer from "@scripts/CountDownTimer.js";

export default class DeliveryTimeCounter {
  #DOMElement = null;
  #data = {};
  #countDownTimer = null;

  constructor(DOMElement) {
    this.#DOMElement = DOMElement;
  }

  init() {
    if (!this.#DOMElement) {
      return;
    }

    this.subscribe();
  }

  subscribe() {
    Mediator.subscribe("ajax_sage_get_app_data", (response) => {
      this.updateData(response?.data?.delivery_time_estimator?.countDownTimer);
    });
  }

  updateData(data) {
    if (!data) {
      return;
    }

    this.#data = data;
    this.updateDisplay();
  }

  updateDisplay() {
    const message = this.prepareMessage();
    if (message) {
      this.#DOMElement.innerHTML = message;
    }
  }

  prepareMessage() {
    let message = this.#data?.message || "";
    let todayIsDeliveryDay = Boolean(this.#data?.todayIsDeliveryDay);

    if (!todayIsDeliveryDay) {
      return message;
    }

    if (!this.#countDownTimer) {
      this.#countDownTimer = new CountDownTimer(
        parseInt(this.#data?.diffTimestamp) || 0,
      );
      this.#countDownTimer.start();
    }

    // Zwracamy message z wstawionym elementem timera
    return message.replace(
      "{{TIME}}",
      this.#countDownTimer.getElement().outerHTML,
    );
  }

  destroy() {
    if (this.#countDownTimer) {
      this.#countDownTimer.stop();
    }
  }
}
