import domReady from "@roots/sage/client/dom-ready";
import LazyLoader from "@scripts/core/LazyLoader.js";
import ProductBuilder from "@scripts/components/product/ProductBuilder.js";
import Gallery from "@scripts/components/product/plugins/Gallery.js";
import QuantityManager from "@scripts/components/QuantityManager.js";
import AddToCartBuilder from "@scripts/components/product/plugins/AddToCart/AddToCartBuilder.js";
import DefaultButton from "@scripts/components/product/plugins/AddToCart/Buttons/DefaultButton.js";
import RadioVariableSelector from "@scripts/components/product/plugins/RadioVariableSelector.js";
import VariationSwitcher from "@scripts/components/product/plugins/VariationSwitcher.js";
import Multipack from "@scripts/components/product/plugins/Multipack.js";
import Omnibus from "@scripts/components/product/plugins/Omnibus.js";

import "fslightbox";
import SingleProductDeliveryTimeCounter from "@scripts/components/product/plugins/SingleProductDeliveryTimeCounter.js";

class Product {
  init() {
    let productDomElement = document.querySelector("[data-single-product]");
    if (productDomElement) {
      let productType = productDomElement.dataset.singleProductType;

      let builder = new ProductBuilder();
      builder.setProductType(productType);
      builder.addPlugin(
        new Gallery(
          ["variable", "simple", "grouped", "external", "variation"],
          "splide",
        ),
      );
      builder.addPlugin(
        new SingleProductDeliveryTimeCounter(
          ["variable", "simple", "grouped", "external", "variation"],
          "[data-delivery-time-counter]",
        ),
      );

      // AddToCartBuilder
      // const addToCartBuilder = new AddToCartBuilder();
      // const addToCartButton = new DefaultButton(productType);
      // addToCartButton.setSize("normal");
      // addToCartBuilder.setProductType(productType);
      // addToCartBuilder.setAddToCartButton(addToCartButton);
      // addToCartBuilder.setAddToCartMode("ajax");
      // addToCartBuilder.setQuantityInput(false);
      // addToCartBuilder.setStickyMode(false);
      // addToCartBuilder.setAddToCartWrapperSelector(
      //   '[data-sticky-add-to-cart="root"]',
      // );
      // addToCartBuilder.setOriginAddToCartFormSelector("form.cart");
      // builder.addPlugin(addToCartBuilder.build());

      builder.addPlugin(new VariationSwitcher(["variable"]));
      builder.addPlugin(new Multipack(["variable", "simple"], productType));
      builder.addPlugin(new Omnibus(["variable"]));

      let product = builder.build();
      product.init();
    }
  }
}

class QuantityModulesBuilder {
  #quantity_module_wrapper_selector = null;
  #origin_module_parent_selector = "form.cart";
  #callbacks = [];

  setModuleWrapperSelector(selector) {
    this.#quantity_module_wrapper_selector = selector;
    return this;
  }

  setOriginModuleParentSelector(selector) {
    this.#origin_module_parent_selector = selector;
    return this;
  }

  addCallback(callback) {
    this.#callbacks.push(callback);
    return this;
  }

  build() {
    if (
      !this.#quantity_module_wrapper_selector ||
      !this.#origin_module_parent_selector
    ) {
      throw new Error("QuantityModulesHandler: No wrapper selector provided");
    }
    return new QuantityManager(
      this.#quantity_module_wrapper_selector,
      this.#origin_module_parent_selector,
      this.#callbacks,
    );
  }
}

function updateCartItemQuantity(wrapper = null, input = null) {
  const updateCartButton = document.querySelector('button[name="update_cart"]');
  if (updateCartButton) {
    updateCartButton.disabled = false;
    updateCartButton.click();
    updateCartButton.disabled = true;
  }
}

const quantityInputsModule = () => {
  const QuantityModulesBuilderInstance = new QuantityModulesBuilder();
  QuantityModulesBuilderInstance.setModuleWrapperSelector(
    "[data-product-quantity-input-wrapper]",
  );
  QuantityModulesBuilderInstance.setOriginModuleParentSelector("form.cart");
  QuantityModulesBuilderInstance.addCallback(updateCartItemQuantity);
  const QuantityManagerInstance = QuantityModulesBuilderInstance.build();
  QuantityManagerInstance.init();
};

const initExpressDelivery = () => {
  const checkbox = document.querySelector(
    '.js-express-delivery-checkbox input[type="checkbox"]',
  );

  if (!checkbox) return;

  let isProcessing = false;

  checkbox.addEventListener("change", async (e) => {
    if (isProcessing) {
      return;
    }

    isProcessing = true;
    const isChecked = e.target.checked;

    // Blokujemy formularz
    jQuery(
      ".woocommerce-checkout-payment, .woocommerce-checkout-review-order-table",
    ).block({
      message: null,
      overlayCSS: {
        background: "#fff",
        opacity: 0.6,
      },
    });

    try {
      const response = await fetch(window.app.ajaxUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "update_express_delivery",
          express_delivery: isChecked ? "yes" : "no",
          nonce: window.app.nonce,
        }),
      });

      const data = await response.json();

      if (data.success) {
        // Aktualizujemy checkout tylko raz
        jQuery(document.body).trigger("update_checkout", {
          update_shipping_method: true,
        });
      } else {
        // Przywracamy poprzedni stan w przypadku błędu
        checkbox.checked = !isChecked;
      }
    } catch (error) {
      console.error("Błąd podczas aktualizacji dostawy ekspresowej:", error);
      checkbox.checked = !isChecked;
    } finally {
      // Odblokowujemy formularz z opóźnieniem
      setTimeout(() => {
        jQuery(
          ".woocommerce-checkout-payment, .woocommerce-checkout-review-order-table",
        ).unblock();
        isProcessing = false;
      }, 500);
    }
  });
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(async () => {
  const lazyLoader = new LazyLoader();
  lazyLoader.registerMethod(quantityInputsModule, "quantityInputsModule");

  const product = new Product();
  product.init();

  // Checkout fee
  initExpressDelivery();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
