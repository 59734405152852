import ProductPluginInterface from "@scripts/components/product/plugins/ProductPluginInterface.js";
import DeliveryTimeCounter from "@scripts/DeliveryTimeCounter.js";

export default class SingleProductDeliveryTimeCounter extends ProductPluginInterface {
  #DOMSelector = null;
  #deliveryTimeCounter = null;

  constructor(compatibleTypes, DOMSelector) {
    super(compatibleTypes);
    this.#DOMSelector = DOMSelector;
  }

  init(productType) {
    if (!super.isCompatible(productType)) {
      return;
    }

    if (!this.#DOMSelector) {
      return;
    }

    const DOMElem = document.querySelector(this.#DOMSelector);

    this.#deliveryTimeCounter = new DeliveryTimeCounter(DOMElem);
    this.#deliveryTimeCounter.init();
  }
}
