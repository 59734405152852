import QuantityInput from "@scripts/components/QuantityInput.js";
import Debugger from "@scripts/core/Debugger.js";
import Mediator from "@scripts/core/Mediator.js";

export default class QuantityManager {
  #modules_map = new Map();
  #selector = "[data-product-quantity-input-wrapper]"; // default selector
  #origin_module_parent_selector = "form.cart"; // default parent selector
  #callbacks = [];

  constructor($selector, $origin_module_parent_selector, $callbacks = []) {
    this.setSelector($selector);
    this.setParentSelector($origin_module_parent_selector);
    this.setCallbacks($callbacks);
    this.subscribeEvents();
  }

  init() {
    let modules = document.querySelectorAll(this.#selector);
    if (!modules) {
      Debugger.debug("modules", modules, "error");
      throw new Error("QuantityManager: No modules found");
    }
    modules.forEach((module) => {
      this.addSingleModule(module);
    });
    Debugger.debug(
      "modules",
      this.#modules_map,
      "info",
      "color: green; background-color: #f1f1f1;",
    );

    this.registerCallbacksInInputs();
  }

  registerCallbacksInInputs() {
    this.validateCallbacks();
    this.#modules_map.forEach((module) => {
      this.#callbacks.forEach((callback) => {
        module.input.addCallback(callback);
      });
    });
  }

  validateCallbacks() {
    this.#callbacks.forEach((callback) => {
      // callback must be a function with two params
      if (typeof callback !== "function") {
        throw new Error("QuantityModulesHandler: Callback must be a function");
      }
    });
  }

  subscribeEvents() {
    Mediator.subscribe("quantityInputChanged", (data) => {
      this.syncInputValues(data.value, data);
    });

    Mediator.subscribe("quantityInputCreated", (module) => {
      this.addSingleModule(module);
    });

    Mediator.subscribe("ajaxUpdatedCartTotals", (module) => {
      this.reinit();
    });

    Mediator.subscribe("changeVariation", (data) => {
      this.reinit();
    });
  }

  addSingleModule(module) {
    if (!this.#modules_map.has(module)) {
      let quantityInput = new QuantityInput(module);

      Debugger.debug(
        "QuantityInput: create",
        quantityInput,
        "info",
        "color: green; background-color: red;",
      );
      this.#modules_map.set(module, {
        input: quantityInput,
        main: !!module.closest(this.#origin_module_parent_selector),
      });
      quantityInput.init();

      Debugger.debug(
        "modules",
        this.#modules_map.get(module),
        "info",
        "color: green; background-color: red;",
      );
    }
  }

  syncInputValues(newValue, caller) {
    let mainExistsFlag = false;
    let mainCounter = 0;
    // check exists element with main true in modules_map
    this.#modules_map.forEach((module) => {
      if (module.main) {
        mainExistsFlag = true;
        mainCounter++;
      }
    });

    if (!mainExistsFlag || mainCounter > 1) {
      return;
    }

    this.#modules_map.forEach((module) => {
      if (module.input !== caller) {
        module.input.setValue(newValue, false);
      }
    });
  }

  setSelector(selector) {
    this.#selector = selector;
  }

  setParentSelector(selector) {
    this.#origin_module_parent_selector = selector;
  }

  reinit() {
    Debugger.debug(
      "reinit",
      null,
      "info",
      "color: red; background-color: #f1f1f1;",
    );
    this.#modules_map.forEach((module) => {
      module.input.destroy();
      // remove object from memory
      this.#modules_map.delete(module);
    });

    this.#modules_map = new Map();

    this.init();
  }

  setCallbacks(callbacks) {
    this.#callbacks = callbacks;
  }
}
