import SingleProduct from "@scripts/components/product/SingleProduct.js";

export default class ProductBuilder {
  #productType = null;
  #pluginListArr = [];

  setProductType(productType) {
    this.#productType = productType;
    return this;
  }

  addPlugin(plugin) {
    if (plugin.isCompatible(this.#productType)) {
      this.#pluginListArr.push(plugin);
    }
    return this;
  }

  build() {
    return new SingleProduct(this.#productType, this.#pluginListArr);
  }
}
